$('.sharer').click(function (e) {
    e.preventDefault();
    var href = $(this).attr('href');
    href = encodeURI(href);
    window.open(href, 'Eurovia share', 'width=600, height=350, location=no, menubar=no, status=no, top=300, left=300');

    return false;
});

var inter = setTimeout(function () {
    $.each($("[owl-carousel-item] img"), function (i, obj) {
        if (!$(obj).hasClass("cropped") && $(obj).attr('src').indexOf("cropsz") < 0) {
            if (obj.height > obj.width) {
                console.log("src= " + $(obj).attr('src'));
                $(obj).attr('src', $(obj).attr('src').replace("/media", "/zbwi.axd/cropsz/290x397/~/media"));
                $(obj).css({ "width": "auto", "padding-left": "229px" });
            } else {
                console.log("src= " + $(obj).attr('src'));
                $(obj).attr('src', $(obj).attr('src').replace("/media", "/zbwi.axd/cropsz/780x397/~/media"));
            }

            $(this).addClass("cropped");
        }
    });
}, 700);